import './App.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';
import { useClearCache } from 'react-clear-cache';

const Orders = lazy(() => import('./pages/orders/orders'));
const OpenOrder = lazy(() => import('./pages/openOrder/openOrder'));
const Admin = lazy(() => import('./pages/admin/admin'));
const New = lazy(() => import('./pages/new/new'));
const Login = lazy(() => import('./pages/login/login'));
const OpenUser = lazy(() => import('./pages/openUser/openUser'));
const Locations = lazy(() => import('./pages/locations/locations'));
const OpenLocation = lazy(() => import('./pages/openLocation/openLocation'));
const NewLocation = lazy(() => import('./pages/newLocation/newLocation'));
const SearchResults = lazy(() => import('./pages/searchResults/searchResults'));
const Reports = lazy(() => import('./pages/reports/reports'));
const NewOrder = lazy(() => import('./pages/newOrder/newOrder'));

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useEffect(() => {
    console.log(isLatestVersion)
    if(!isLatestVersion) {
      emptyCacheStorage()
    }
  }, [])

  return (
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <Route exact path='/' component={Login} />
          <Route exact path='/orders' component={Orders} />
          <Route exact path='/orders/filter/:status' component={Orders} />
          <Route exact path='/orders/:id' component={OpenOrder} />
          <Route exact path='/admin/users' component={Admin} />
          <Route exact path='/admin/new' component={New} />
          <Route exact path='/admin/users/:id' component={OpenUser} />
          <Route exact path='/admin/locations' component={Locations} />
          <Route exact path='/admin/locations/:id' component={OpenLocation} />
          <Route exact path='/admin/new/location' component={NewLocation} />
          <Route exact path='/search/:orderNum' component={SearchResults} />
          <Route exact path='/admin/reports' component={Reports} />
          <Route exact path='/neworder' component={NewOrder} />
        </Router>
      </Suspense>
  );
}

export default App;